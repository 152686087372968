import React from 'react';
import styled from 'styled-components';
import { Color } from '../../typescript/types';

interface Props {
  fontSizes?: [string, string, string];
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
  children: React.ReactNode;
  padding?: string;
  lineHeight?: number;
  color?: Color;
  fontWeight?: 300 | 400 | 500 | 600 | 700;
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
  link?: 'internal' | 'external';
}

export default ({
  children,
  fontSizes = ['0.875rem', '1rem', '1.125rem'],
  textAlign = 'left',
  textTransform = 'none',
  padding,
  lineHeight = 1.7,
  color = 'grey700',
  fontWeight = 400,
  link,
}: Props) => (
  <P
    color={color}
    lineHeight={lineHeight}
    padding={padding}
    textAlign={textAlign}
    fontSizes={fontSizes}
    textTransform={textTransform}
    fontWeight={fontWeight}
  >
    {children}
  </P>
);

const P = styled.p<Props>`
  text-align: ${({ textAlign }) => textAlign};
  font-size: ${({ fontSizes }) => fontSizes![2]};
  padding: ${({ padding }) => padding};
  text-transform: ${({ textTransform }) => textTransform};
  line-height: ${({ lineHeight }) => lineHeight};
  max-width: 60ch;
  color: ${({ theme, color }) => theme.colors[color!]};
  font-weight: ${({ fontWeight }) => fontWeight};
  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: ${({ fontSizes }) => fontSizes![1]};
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    font-size: ${({ fontSizes }) => fontSizes![0]};
  }
`;
