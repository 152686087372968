import React, { useState } from 'react'

import Img, { FluidObject } from 'gatsby-image'
import styled from 'styled-components'
import Hr from '../components/BlogPosts/utils/Hr'
import Container from '../components/Container'
import Footer from '../components/Footer/Footer'
import Heading from '../components/Heading'
import Intro from '../components/Intro'
import Paragraph from '../components/Paragraph'
import VideoLightbox from '../components/VideoLightbox'

import { PageProps, graphql, useStaticQuery } from 'gatsby'
import { SEO } from '../SEO'
import { GridColumn, GridRow } from '../components/Grid'
import { Site } from '../components/Site'
import { Main } from '../components/Styled'
import WhitepaperSlider from '../components/Whitepaper/Swiper'
import { flatMap } from '../components/utils/flatMap'

export default (props: PageProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const data = useStaticQuery(graphql`
    query Placeholder {
      placeholder: file(relativePath: { eq: "ai.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fagma: file(relativePath: { eq: "whitepaper/fagma-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ai: file(relativePath: { eq: "whitepaper/ai-brain.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hype: file(relativePath: { eq: "whitepaper/hype-cycle.png" }) {
        childImageSharp {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pullQuote: file(relativePath: { eq: "whitepaper/pullquote.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      unstructuredData: file(relativePath: { eq: "whitepaper/unstructured-data.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      how: file(relativePath: { eq: "whitepaper/how.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      stonks: file(relativePath: { eq: "whitepaper/stonks.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      richard: file(relativePath: { eq: "RichardKimber/assets/RichardKimber.jpeg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            src
          }
        }
      }
    }
  `)

  const { fluid: placeholder } = data?.placeholder?.childImageSharp
  const { fluid: fagma } = data?.fagma?.childImageSharp
  const { fluid: aiBrain } = data?.ai?.childImageSharp
  const { fluid: hype } = data?.hype?.childImageSharp
  const { fluid: pullQuote } = data?.pullQuote?.childImageSharp
  const { fluid: unstructuredData } = data?.unstructuredData?.childImageSharp
  const { fluid: how } = data?.how?.childImageSharp
  const { fluid: stonks } = data?.stonks?.childImageSharp
  const { fluid: richard } = data?.richard?.childImageSharp

  return (
    <Site seo={SEO.ai} {...props}>
      <Main>
        <Intro bgColor='purple100'>
          <Container>
            <GridColumn id='video' gap='1.5rem'>
              <GridRow>
                <Heading tag='h1'>The Future of AI</Heading>
                <Paragraph>
                  Artificial intelligence (A.I.) is the fourth industrial revolution, and we are currently in a
                  fundamental period of change at a structural level (World Economic Forum). A.I. is altering the way we
                  innovate and will inevitably touch every facet of our lives moving forward.
                </Paragraph>
              </GridRow>

              <VideoLightbox
                src='https://res.cloudinary.com/harrybu/video/upload/v1605565191/AI_intro_video_breakfast_v.4_n9pwmh'
                placeholder={<Img fluid={placeholder} alt='ai' />}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                credits='Credits: TED Conferences LLC and McKinsey & Company'
              />
            </GridColumn>
          </Container>
        </Intro>

        <Section id='whitepaper'>
          <Container padding={flatMap(['6rem 1.5rem'])}>
            <GridRow justifyItems='center'>
              <Paragraph fontWeight={600} textTransform='uppercase' textAlign='center' color='grey700'>
                Whitepaper
              </Paragraph>
              <Heading tag='h3' textAlign='center' lineHeight={1.3}>
                The State of Artificial Intelligence (AI)
              </Heading>
              <Paragraph fontWeight={500} textAlign='center' color='grey500'>
                Macquarie University AI Incubator by Richard Kimber, daisee Founder & Director
              </Paragraph>
              <Hr />
            </GridRow>
          </Container>
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <GridRow>
                <Heading tag='h4' fontSizes={['1.5rem', '1.75rem', '2rem']} color='green' lineHeight={1.3}>
                  Executive Summary
                </Heading>
                <Richard fluid={richard} />
              </GridRow>
              <Paragraph>
                Drawing from subject matter experts and industry best practice, we have compiled key insights into the
                current and future states of AI that are consistent with Government policy, Gartner and McKinsey.​
                <br />
                <br />
                This introspective was also based on a 2020 Keynote: Latest Advances in Artificial Intelligence from
                Richard Kimber at the Macquarie University Incubator, leveraging the proprietary daisee transcription
                and analysis solution.
                <br />
                <br />
                ​Fundamentally, AI will be a part of the future ways of working and companies must proactively approach
                the challenge to emerge successful.​
              </Paragraph>
            </GridColumn>
            <Hr margin='3rem 0' />
          </Container>
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Heading tag='h4' fontSizes={['1.5rem', '1.75rem', '2rem']} color='green' lineHeight={1.3}>
                AI is the next global Arms Race
              </Heading>
              <Paragraph>
                An AI strategy is key to navigating the complexity of the fourth industrial revolution.​
                <br />
                <br /> ​While the assumption would be to consider the United States or China as leaders, it is evident
                the United Kingdom has positioned themselves to confront the emerging grand challenges of:​
                <br />
                <br /> <Paragraph fontWeight={600}>​1. AI and data revolution;</Paragraph>
                <br /> <Paragraph fontWeight={600}>​2.​ World leader in mobility; ​</Paragraph>
                <br /> <Paragraph fontWeight={600}>​​3. A shift to clean growth;</Paragraph>​
                <br /> <Paragraph fontWeight={600}>​​4. Meeting the needs of an ageing society</Paragraph>​
                <br /> ​​​In scope, these challenges are universal and similarly apply to Australia. The UK has
                developed a series of productivity foundations that are proven to increase improvements in and beyond
                the economy.​
              </Paragraph>
            </GridColumn>
          </Container>
          <Container margin='6rem auto' padding={flatMap(['0 0'])}>
            <WhitepaperSlider />
            <Hr margin='3rem 0' />
          </Container>
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Heading tag='h4' fontSizes={['1.5rem', '1.75rem', '2rem']} color='green' lineHeight={1.3}>
                Key Findings
              </Heading>
              <Paragraph>
                1. From mobile to AI first: FAGMA (Facebook, Apple, Microsoft, Google, Amazon) companies have enjoyed
                financial success that has accelerated in the pandemic because they focused on developing AI strategy at
                all levels of the business
                <br /> <br /> ​ 2. Unstructured data: is the key challenge to overcome for machine learning to be truly
                effective in optimizing productivity ​ <br /> <br />
                3. AI Service providers: Rather than investing intensive resources into internal AI, there will be
                emerging AI SAAS firms to affordably take advantage
              </Paragraph>
            </GridColumn>
          </Container>
          <br />
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Container padding={flatMap(['0 0'])}>
                <StyledImg fluid={stonks} alt='FAGMA stocks' />
              </Container>
              <Paragraph>
                - The FAGMA companies have all consistently chosen to be AI first in their strategy.
                <br />
                <br />- The mystery behind ‘innovative and secret’ algorithms is now commonly understood.​
                <br />
                <br /> - The key point of differentiation is the approach to understanding and using data, and this is
                where FAGMA dominates.​ <br />
                <br />- Concerningly, the USA and China are the major players and there is a significant lack of
                Australian presence. ​ <br />
                <br />- The pandemic has accelerated the impact of an AI first approach, and the FAGMA performance shows
                in their financial success globally.​
              </Paragraph>
              <span />
            </GridColumn>
            <Container padding={flatMap(['0 0'])}>
              <StyledImg fluid={fagma} alt='fagma' />
            </Container>
            <Hr margin='3rem 0' />
          </Container>
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Heading tag='h4' fontSizes={['1.5rem', '1.75rem', '2rem']} color='green' lineHeight={1.3}>
                What can the public and private sectors learn?​
              </Heading>
              <Paragraph>
                - Become proactive, rather than reactive to encourage innovation.
                <br />
                <br />​ - Move towards becoming a “Tech-Nation” that defines a technology strategy for the whole
                country.
                <br />
                <br />​ - A committed partnership in innovation between businesses and universities to solve tomorrow’s
                challenges.​
              </Paragraph>
            </GridColumn>
            <Hr margin='3rem 0' />
          </Container>
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Heading tag='h4' fontSizes={['1.5rem', '1.75rem', '2rem']} color='green' lineHeight={1.3}>
                AI vs Machine Learning
              </Heading>
              <Paragraph>
                - AI and machine learning are not just a new kind of software but are completely new and unique.​ <br />
                <br />- Machine learning is a sub-set of AI that trains models to predict outcomes. AI machine learning
                recognises patterns and the more data that you serve the algorithm, the better is gets at pattern
                recognition.​ <br />
                <br />- This is very different to normal, traditional software that uses rules-based logic in a very
                systematic way to determine if this happens, do that.​ <br />
                <br />- Machine learning may be very accurate, up to 95%, but will never be perfect. However, humans are
                not perfect; it is important to calibrate AI to what a human can do, and in this measure, AI can
                replicate a human level of accuracy.​
              </Paragraph>
            </GridColumn>
          </Container>
          <Container margin='3rem auto' padding={flatMap(['0 0'])} fullWidth hasBorder>
            <Img fluid={aiBrain} alt='ai brain' />
          </Container>
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Heading tag='h4' fontSizes={['1.5rem', '1.75rem', '2rem']} color='green' lineHeight={1.3}>
                What is the AI hype?
              </Heading>
              <Paragraph>
                - AI has been over-hyped in the media repeatedly since 1956, but we are reaching a tipping point - as
                Gartner identifies.​ <br />
                <br /> ​- Popular visions of AI reaching Singularity is unrealistic, however, real prediction is
                possible and the next battleground for companies and countries.​ <br />
                <br /> - AI has the capacity to augment people and their performance, as we can spend more time being
                productive. <br />
                <br /> ​ - While the results of some elements such as chatbots are unsatisfactory, there is a new
                horizon emerging in computer vision.​
              </Paragraph>
              <span />
              <Container margin='3rem auto' padding={flatMap(['0 0'])}>
                <StyledImg fluid={hype} alt='ai hype' />
              </Container>
            </GridColumn>
            <Hr margin='3rem 0' />
          </Container>{' '}
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Heading tag='h4' fontSizes={['1.5rem', '1.75rem', '2rem']} color='green' lineHeight={1.3}>
                Why is AI Important
              </Heading>
              <Paragraph>
                - Analytical tasks and finding patterns in data that was done by software, can be done more efficiently
                and at lower cost with AI​
                <br />
                <br /> - Advances in algorithms, training data, GPU’s and Cloud services along with greater interest and
                investment have created the perfect conditions to finally realise change.​ <br />
                <br /> <Paragraph fontWeight={600}>1. Innovation: New products and services will emerge.​</Paragraph>
                <br />
                <Paragraph fontWeight={600}>
                  2. Efficacy & Velocity: Productivity and tasks will be achieved far more effectively and quicker.
                </Paragraph>
                <br />
                <Paragraph fontWeight={600}>3. Scalability: Tasks will be extended to more participants​</Paragraph>
              </Paragraph>
            </GridColumn>
          </Container>{' '}
          <Container margin='3rem auto' padding={flatMap(['0 0'])} fullWidth hasBorder>
            <Img fluid={pullQuote} alt='ai brain' />
          </Container>
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Heading tag='h4' fontSizes={['1.5rem', '1.75rem', '2rem']} color='green' lineHeight={1.3}>
                The opportunity of unstructured data
              </Heading>
              <Paragraph>
                - Computer vision is the key to understanding abstract concepts that work with AI and Machine Learning.​
                <br />
                <br />
                - In 2013, there was a breakthrough – we could convert words into representation or vectors (Word2Vec)
                that explore the common relationships in words.​
                <br />
                <br />- This allows AI to understand the context and meaning behind the words in a numerical sense.​{' '}
                <br />
                <br />- It changed the way AI can evaluate abstract concepts into close approximations of meaning.{' '}
                <br />
                <br />
                This has opened extensive new possibilities with understanding the data. However, Most data is
                unstructured - just like our world. Language is the same, and for AI and machine learning to work, they
                need structure.​ ​
              </Paragraph>
            </GridColumn>
            <Hr margin='3rem 0' />
          </Container>{' '}
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Container margin='3rem auto' padding={flatMap(['0 0'])}>
                <Img fluid={unstructuredData} alt='data diagram' />
              </Container>
              <Paragraph>
                - 80% of corporate data will be unstructured by 2025 (IDC) <br />
                <br />
                ​- This is where Machine Learning and AI can make the biggest impact​ ​ <br />
                <br />- CRM systems will become autonomous in detecting information relevant to your customers rather
                than relying on static data​ <br />
                <br />- The data requires more storage, and it is harder to manage and protect with legacy solutions.​ -
                The amount of data is also growing, expected to reach 135 Zetabytes by 2025 (Data Age).​ <br />
                <br />- The magnitude of this would be to imagine attempting to collect and categorise every grain of
                sand on planet Earth for every Zetabyte of data!
              </Paragraph>
            </GridColumn>
            <Hr margin='3rem 0' />
          </Container>{' '}
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Heading tag='h4' fontSizes={['1.5rem', '1.75rem', '2rem']} color='green' lineHeight={1.3}>
                Why AI is essential for the success of businesses​
              </Heading>
              <Paragraph>
                - No longer relying on historic data but rather, AI can be used to predict future opportunities and
                threats​ <br />
                <br />- Optimisation – AI will not only have a dramatic impact on how businesses develop their
                go-to-market strategy but how to optimise it across different segments​
                <br />
                <br />- Churn prediction using insights from AI and machine learning – it is far easier to retain a
                customer than earn a new one​
              </Paragraph>
            </GridColumn>
            <Hr margin='3rem 0' />
          </Container>{' '}
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Heading tag='h4' fontSizes={['1.5rem', '1.75rem', '2rem']} color='green' lineHeight={1.3}>
                What can be done?​
              </Heading>
              <Paragraph>
                - Necessary Skills ​ Awareness is key to putting AI first. While technical knowledge at every level
                isn’t needed, CEO and leadership need to join the AI conversation rather than delegating to R&D. ​​
                <br />
                <br />- Identifying relevant use cases​ Knowing when, where and how to apply AI is the most important
                barrier to overcome.​ <br />
                <br />- Defining AI Strategy​ <br />
                <br />
                By understanding AI at a leadership level, companies can develop effective strategies to realise the
                rewards.​​
              </Paragraph>
            </GridColumn>
          </Container>{' '}
          <Container margin='3rem auto' padding={flatMap(['0 0'])} fullWidth hasBorder>
            <Img fluid={how} alt='as services' />
          </Container>
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Heading tag='h4' fontSizes={['1.5rem', '1.75rem', '2rem']} color='green' lineHeight={1.3}>
                How will AI Services work?​
              </Heading>
              <Paragraph>
                - Like Software as a Service products (SASS), AI will follow a similar approach.​ <br />
                <br />- AI will enable businesses to increase the velocity of innovation and continuously evolve through
                rapid release cycling.​ <br />
                <br />- Fundamentally, AI as a service lowers the cost of entry for customers because it removes the
                costly development component and can be purchased or rented. <br />
                <br />- There are also several downstream integrations.​
              </Paragraph>
            </GridColumn>
            <Hr margin='3rem 0' />
          </Container>
          <Container padding={flatMap(['0 1.5rem'])}>
            <GridColumn gap='1.5rem'>
              <Heading tag='h4' fontSizes={['1.5rem', '1.75rem', '2rem']} color='green' lineHeight={1.3}>
                We are daisee
              </Heading>
              <Paragraph>
                We are daisee, a conversational middleware that automates the quality management process.
                <br />
                <br />
                ​Fundamentally we deal with unstructured interaction data and create structure in it to assist our
                clients with unearthing actionable insight.
                <br />
                <br />
                ​Recognised domestically and abroad, daisee is named as a Cool Vendor by Gartner, and in the KMPG
                Fintech Global 100, one of 7 Australian companies. <br />
                <br />
                ​Watch the full presentation here​
              </Paragraph>
              <VideoWrapper>
                <iframe
                  title='Maquarie Incubator'
                  height='100%'
                  width='100%'
                  src='https://www.youtube.com/embed/XOyOvHlTOyg'
                />
              </VideoWrapper>
            </GridColumn>
          </Container>
        </Section>
      </Main>
      <Footer />
    </Site>
  )
}

interface StyledImgProps {
  fluid: FluidObject
}

const Richard = styled(Img)<StyledImgProps>`
  border-radius: 50%;
  max-width: 150px;
  max-height: 150px;
  height: 150px;
  width: 150px;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.boxShadow.medium};
  border: 2px solid ${({ theme }) => theme.colors.purple};
`

// todo: Move both to components
const StyledImg = styled(Img)<StyledImgProps>`
  box-shadow: ${({ theme }) => theme.boxShadow.medium};
  border-radius: 16px;
  margin: 1.5rem;
`

const VideoWrapper = styled.div`
  position: relative;
  grid-column: -1/1;
  margin: 3rem 0 9rem;
  padding-bottom: 56.25%;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Section = styled.section`
  background: #fff;
`
