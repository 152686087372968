import React from 'react'
import { XCircle, PlayCircle } from 'react-feather'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Container from '../Container'

interface Props {
  isOpen: boolean
  setIsOpen: (_: boolean) => void
  src: string
  credits: string
  placeholder?: JSX.Element
}

export default ({ isOpen, setIsOpen, src, credits, placeholder }: Props) => (
  <AnimatePresence>
    <Container>
      <Placeholder>
        <Play onClick={() => setIsOpen(!isOpen)}>
          <PlayCircle size={96} color='#fff' />
        </Play>
        {placeholder || <img src={`${src}.jpg`} alt='' />}
      </Placeholder>
      <Credit>{credits}</Credit>
    </Container>
    {isOpen && (
      <Lightbox initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Inner>
          <Close onClick={() => setIsOpen(!isOpen)}>
            <XCircle color='#fff' size={32} />
          </Close>
          <VideoWrapper>
            <video preload='none' autoPlay playsInline controls poster={`${src}.jpg`}>
              <source src={`${src}.mp4`} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </VideoWrapper>
        </Inner>
      </Lightbox>
    )}
  </AnimatePresence>
)

const Credit = styled.p`
  font-weight: 600;
  padding: 1.5rem;
`

const Lightbox = styled(motion.div)`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.75);
  z-index: 9999;
`

const Inner = styled.div`
  padding: 6rem;
  height: 100vh;

  @media all and (max-width: 31.25em) {
    padding: 6rem 0;
  }
`

const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 6rem;
  right: 3rem;

  ${({ theme }) => theme.breakpoints.tablet} {
    top: 9rem;
  }
`

const VideoWrapper = styled.div`
  height: 0;
  top: 0;
  padding-bottom: 0.5625%;
  position: relative;
  height: 100%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`

const Placeholder = styled.div`
  position: relative;
  box-shadow: ${({ theme }) => theme.boxShadow.large};
  border-radius: 16px;
  overflow: hidden;
`

const Play = styled.button`
  border: none;
  outline: none;
  position: absolute;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 48px);
  left: 0;
  right: 0;
  text-align: center;
  background: none;
  z-index: 50;
  cursor: pointer;

  &:hover {
    svg {
      transform: scale(1.05);
      stroke: ${({ theme }) => theme.colors.green};
    }
  }

  svg {
    stroke-width: 1.5px;
    transition: all 0.3s ease;
  }
`
