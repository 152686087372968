import React from 'react';
import styled from 'styled-components';

interface Props {
  id?: string;
  gap?: string;
  justifyItems?: 'center' | 'start' | 'end' | 'auto' | 'stretch';
}

export const GridColumn: React.FC<Props> = ({ children, id, gap }) => (
  <StyledGridColumn id={id} gap={gap}>
    {children}
  </StyledGridColumn>
);

export const GridRow: React.FC<Props> = ({ children, id, gap = '3rem', justifyItems = 'stretch' }) => (
  <StyledGridRow id={id} gap={gap} justifyItems={justifyItems}>
    {children}
  </StyledGridRow>
);

const StyledGridColumn = styled.div<Props>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  height: fit-content;
  gap: ${({ gap }) => gap};

  ${({ theme }) => theme.breakpoints.tablet} {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
`;

const StyledGridRow = styled.div<Props>`
  display: grid;
  position: relative;
  height: fit-content;
  gap: ${({ gap }) => gap};
  justify-items: ${({ justifyItems }) => justifyItems};

  ${({ theme }) => theme.breakpoints.tablet} {
    grid-template-columns: 1fr;
  }
`;
