import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import Heading from '../Heading';
import Container from '../Container';

import { GridRow } from '../Grid';

const productivityPoints = [
  { id: 'Ideas', text: 'Create the world’s most innovative economy​' },
  { id: 'People', text: 'Create better jobs and greater earning power for all​' },
  { id: 'Infrastructure', text: 'Initiate a major upgrade to the UK’s infrastructure​​' },
  { id: 'Business Environment', text: 'Design conditions to start and grow a business' },
  { id: 'Places', text: 'Establish prosperous communities​' },
];

const variants = {
  hidden: { x: '-25%', opacity: 0 },
  show: { x: '0', opacity: 1 },
  exit: { x: '25%', opacity: 0 },
};

const WhitepaperSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleClick = (i: number) => setActiveSlide(i);

  return (
    <GridRow gap="3rem">
      <Heading tag="h3" textAlign="center" fontSizes={['1.25rem', '1.5rem', '2rem']} color="purple">
        5 Foundations of Productivity​ 🇬🇧
      </Heading>
      <NumberWrapper>
        {productivityPoints.map((_, i) => (
          <Number isActive={activeSlide === i} onClick={() => handleClick(i)}>
            {i + 1}
          </Number>
        ))}
      </NumberWrapper>

      <Container>
        <AnimatePresence initial={false} exitBeforeEnter>
          {productivityPoints[activeSlide] && (
            <Slide
              key={productivityPoints[activeSlide].id}
              variants={variants}
              animate="show"
              exit="exit"
              initial="hidden"
              transition={{ ease: [0.7, -0.005, 0, 1.005], duration: 0.5 }}
            >
              <GridRow justifyItems="center">
                <Heading tag="h4" textAlign="center" fontSizes={['1.25rem', '1.5rem', '2rem']}>
                  {productivityPoints[activeSlide].id}
                </Heading>
                <Heading
                  color="grey700"
                  tag="h5"
                  textAlign="center"
                  fontSizes={['1.125rem', '1.25rem', '1.5rem']}
                  lineHeight={1.2}
                >
                  {productivityPoints[activeSlide].text}
                </Heading>
              </GridRow>
            </Slide>
          )}
        </AnimatePresence>
      </Container>
    </GridRow>
  );
};

interface NumberProps {
  isActive?: boolean;
}

const active = css`
  color: ${({ theme }) => theme.colors.purple};
  &:before {
    content: '';
    width: 5rem;
    height: 6px;
    background: ${({ theme }) => theme.colors.green};
    bottom: 0;
    position: absolute;
    left: calc(50% - 2.5rem);
    ${({ theme }) => theme.breakpoints.mobile} {
      width: 3rem;
      left: calc(50% - 1.5rem);
    }
  }
`;

const Slide = styled(motion.div)`
  overflow: hidden;
`;

const Number = styled.p<NumberProps>`
  color: transparent;
  font-size: 8rem;
  text-align: center;
  position: relative;
  line-height: 1.5;
  cursor: pointer;
  font-weight: 500;
  -webkit-text-stroke: 1px ${({ theme }) => theme.colors.purple};

  ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 4rem;
  }
  ${({ isActive }) => isActive && active}
`;

const NumberWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: fit-content;
  padding: 0 9rem;
  justify-content: center;
  justify-items: center;

  ${({ theme }) => theme.breakpoints.tablet} {
    padding: 0 6rem;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    padding: 0 3rem;
  }
`;

export default WhitepaperSlider;
